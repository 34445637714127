import { decamelizeKeys } from "humps";
import JWT from "jsonwebtoken";

const readerUrl = (issue) => {
  const userToken = process.env.REACT_APP_API_SECRETE;
  const extraParams = {};
  let userData = {
    apiKey: userToken,
    api_key: userToken,
    kiosk: process.env.REACT_APP_KIOSK_ID,
    ...extraParams,
  };

  let oHeader = { algorithm: "HS256" };
  issue = {
    ...issue,
    ...decamelizeKeys(issue),
  };
  let oPayload = {
    publication: {
      id: issue.publicationId,
      name: issue.publicationName,
      publication_number: {
        ...issue,
      },
      issue,
    },
    userData,
    apiUrl: process.env.REACT_APP_API_URL + "/api",
    // apiUrl: process.env.REACT_APP_API_URL || "https:/prod.kioskp.com/api",
    // apiUrl: "https://prod.lekiosknumerique.com/api",
    ...extraParams,
  };
  const token = JWT.sign(oPayload, "616161", oHeader);
  console.log("token", token);
  return `${process.env.REACT_APP_READER_URL}${token}`;
};
export default readerUrl;
