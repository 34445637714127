import { isNumber, nth, random, toArray } from "lodash";
import { CALL_API, Schemas } from "../middleware/api";

export const RESET_ERROR_MESSAGE = "RESET_ERROR_MESSAGE";

// Resets the currently visible error message.
export const resetErrorMessage = () => ({
  type: RESET_ERROR_MESSAGE,
});

export const KIOSK_CATEGORIES_REQUEST = "KIOSK_CATEGORIES_REQUEST";
export const KIOSK_CATEGORIES_SUCCESS = "KIOSK_CATEGORIES_SUCCESS";
export const KIOSK_CATEGORIES_FAILURE = "KIOSK_CATEGORIES_FAILURE";

// Fetches a page of stargazers for a particular repo.
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchCategories = (kioskId, options = {}) => ({
  kioskId,
  [CALL_API]: {
    types: [
      KIOSK_CATEGORIES_REQUEST,
      KIOSK_CATEGORIES_SUCCESS,
      KIOSK_CATEGORIES_FAILURE,
    ],
    endpoint: "/kiosk/categories",
    schema: Schemas.KIOSK_CATEGORY_ARRAY,
    options,
  },
});

// Fetches a page of stargazers for a particular repo.
// Bails out if page is cached and user didn't specifically request next page.
// Relies on Redux Thunk middleware.
export const loadCategories = () => (dispatch, getState) => {
  const kioskId =
    getState()?.profile?.user?.kiosk?.id || process.env.REACT_APP_KIOSK_ID;

  return dispatch(
    fetchCategories(kioskId, {
      params: {
        // pdf: false,
      },
    })
  );
};

export const KIOSK_CATEGORY_ITEMS_REQUEST = "KIOSK_CATEGORY_ITEMS_REQUEST";
export const KIOSK_CATEGORY_ITEMS_SUCCESS = "KIOSK_CATEGORY_ITEMS_SUCCESS";
export const KIOSK_CATEGORY_ITEMS_FAILURE = "KIOSK_CATEGORY_ITEMS_FAILURE";

// Fetches a page of stargazers for a particular repo.
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchCategoryItems = (categoryId, options = {}, actionOptions = {}) => ({
  categoryId,
  [CALL_API]: {
    types: [
      KIOSK_CATEGORY_ITEMS_REQUEST,
      KIOSK_CATEGORY_ITEMS_SUCCESS,
      KIOSK_CATEGORY_ITEMS_FAILURE,
    ],
    endpoint: `/kiosk/categories/${categoryId}/items`,
    schema: Schemas.KIOSK_CATEGORY_ITEM,
    options,
    ...actionOptions,
  },
});

// Fetches a page of stargazers for a particular repo.
// Bails out if page is cached and user didn't specifically request next page.
// Relies on Redux Thunk middleware.
export const loadCategoryItems =
  (id, nextPage, reload = false) =>
  (dispatch, getState) => {
    if (reload) {
      return dispatch(
        fetchCategoryItems(id, {
          params: {
            pdf: true,
          },
        })
      );
    }

    const { pageCount = 1, hasMoreResult = true } =
      getState().pagination.categoryItems[id] || {};

    if (pageCount > 1 && !nextPage) {
      return null;
    }
    if (!hasMoreResult) {
      return null;
    }

    return dispatch(
      fetchCategoryItems(id, {
        params: {
          pdf: true,
        },
      })
    );
  };

export const ISSUE_REQUEST = "ISSUE_REQUEST";
export const ISSUE_SUCCESS = "ISSUE_SUCCESS";
export const ISSUE_FAILURE = "ISSUE_FAILURE";

// Fetches a single user from Github API.
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchIssue = (id) => ({
  [CALL_API]: {
    types: [ISSUE_REQUEST, ISSUE_SUCCESS, ISSUE_FAILURE],
    endpoint: `/kiosk/issues/${id}`,
    schema: Schemas.ISSUE,
    options: {
      params: {
        pdf: true,
      },
    },
  },
});

// Fetches a single user from Github API unless it is cached.
// Relies on Redux Thunk middleware.
export const loadIssue =
  (id, requiredFields = []) =>
  (dispatch, getState) => {
    const issue = getState().entities.issues[id];

    if (issue && requiredFields.every((key) => issue.hasOwnProperty(key))) {
      return null;
    }
    // return null;

    return dispatch(fetchIssue(id));
  };

export const TAG_OJD_ISSUE_REQUEST = "TAG_OJD_ISSUE_REQUEST";
export const TAG_OJD_ISSUE_SUCCESS = "TAG_OJD_ISSUE_SUCCESS";
export const TAG_OJD_ISSUE_FAILURE = "TAG_OJD_ISSUE_FAILURE";

const fetchTrackOjd = (url) => ({
  [CALL_API]: {
    types: [
      TAG_OJD_ISSUE_REQUEST,
      TAG_OJD_ISSUE_SUCCESS,
      TAG_OJD_ISSUE_FAILURE,
    ],
    endpoint: url,
    schema: {},
    useNormalizer: false,
  },
});
const trackOjd = (url) => (dispatch) => {
  dispatch(fetchTrackOjd(url));
};

export const LOG_STATS_ISSUE_REQUEST = "LOG_STATS_ISSUE_REQUEST";
export const LOG_STATS_ISSUE_SUCCESS = "LOG_STATS_ISSUE_SUCCESS";
export const LOG_STATS_ISSUE_FAILURE = "LOG_STATS_ISSUE_FAILURE";

const fetchLogStats = (id) => async (dispatch) => ({
  [CALL_API]: {
    types: [
      LOG_STATS_ISSUE_REQUEST,
      LOG_STATS_ISSUE_SUCCESS,
      LOG_STATS_ISSUE_FAILURE,
    ],
    endpoint: `/statistics/${id}`,
    schema: {},
    useNormalizer: false,
    options: {
      method: "post",
    },
  },
});

const logStats = (id) => async (dispatch) => {
  dispatch(fetchLogStats(id));
};

export const trackIssue = (issue) => (dispatch, getState) => {
  if (isNumber(issue)) {
    issue = getState().entities.issues[issue];
  }
  const logActions = [logStats(issue?.id)];
  if (issue?.ojd?.url) {
    !process.env.NODE_ENV ||
      (process.env.NODE_ENV === "development" &&
        logActions.push(trackOjd(issue.ojd.url)));
  }
  return dispatch(logActions);
};

export const radomTrackOjdIssue = () => (dispatch, getState) => {
  const issues = toArray(getState().entities.issues);
  const index = random(issues.length);
  const issue = nth(issues, index);
  if (!issue) {
    return null;
  }

  return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? dispatch(trackOjd(issue.ojd.url))
    : null;
};

export const PUBLICATION_REQUEST = "PUBLICATION_REQUEST";
export const PUBLICATION_SUCCESS = "PUBLICATION_SUCCESS";
export const PUBLICATION_FAILURE = "PUBLICATION_FAILURE";

const fetchPublication = (id) => ({
  publicationId: id,
  [CALL_API]: {
    types: [PUBLICATION_REQUEST, PUBLICATION_SUCCESS, PUBLICATION_FAILURE],
    endpoint: `/publications/${id}`,
    schema: Schemas.PUBLICATION,
  },
});

export const loadPublication = (id) => async (dispatch) => {
  dispatch(fetchPublication(id));
};

export const PUBLICATION_ISSUES_REQUEST = "PUBLICATION_ISSUES_REQUEST";
export const PUBLICATION_ISSUES_SUCCESS = "PUBLICATION_ISSUES_SUCCESS";
export const PUBLICATION_ISSUES_FAILURE = "PUBLICATION_ISSUES_FAILURE";

const fetchPublicationIssues = (
  publicationId,
  options = {},
  actionOptions = {}
) => ({
  publicationId,
  ...actionOptions,
  [CALL_API]: {
    types: [
      PUBLICATION_ISSUES_REQUEST,
      PUBLICATION_ISSUES_SUCCESS,
      PUBLICATION_ISSUES_FAILURE,
    ],
    endpoint: `/publications/${publicationId}/issues`,
    schema: Schemas.ISSUE,
    options,
  },
});
export const loadPublicationIssues =
  (id, nextPage, reload = false) =>
  (dispatch, getState) => {
    if (reload) {
      return dispatch(fetchPublicationIssues(id));
    }

    const { pageCount = 1, hasMoreResult = true } =
      getState().pagination.publicationIssues[id] || {};

    // console.log(pageCount > 1 && !nextPage);
    // if (pageCount > 1  && !nextPage) {
    //   return null;
    // }
    if (!hasMoreResult) {
      return null;
    }

    return dispatch(
      fetchPublicationIssues(
        id,
        {
          params: {
            page: pageCount,
          },
        },
        {
          isLoadingMore: nextPage === true,
        }
      )
    );
  };
