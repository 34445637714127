import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { findDOMNode } from "react-dom";

const NetworkStatus = () => {
  const online = useNetworkStatus();
  const modal = useRef();

  useEffect(() => {
    if (online !== undefined) {
      if (online) {
        //dismiss overlay
        // $(modal.current).modal("hide");
      } else {
        //show overlay
        // $(modal.current).modal("show");
      }

      const el = findDOMNode(modal.current);
      $(modal.current).data("coucu", "parfert");

      $(el).slideUp();
    }
  }, [online]);

  if (online) {
    return null;
  }
  return (
    <div
      className="d-flex justify-content-center align-items-center h-100 text-light "
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 9999,
        backgroundColor: "rgba(0,0,0,0.7)",
      }}
    >
      <div className="text-center">
        <i className="fa fa-wifi fa-10x" />
        <h1>No internet connection</h1>
        <p>Your connection appears to be off-line.</p>
      </div>
    </div>
  );
};

export const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(null);

  useEffect(() => {
    setIsOnline(navigator.onLine);
  }, []);

  useEffect(() => {
    window.addEventListener("online", () => {
      // Set hasNetwork to online when they change to online.
      setIsOnline(true);
    });

    window.addEventListener("offline", () => {
      // Set hasNetwork to offline when they change to offline.
      setIsOnline(false);
    });
  }, []);
  return isOnline;
};
export default NetworkStatus;
