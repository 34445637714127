import * as AuthActionTypes from '../actions/library';

const updateIssue = (
  state = {
    issue: null,
    progress: 0,
    error: null,
    jobId: null,
    completed: false,
    pageIndex: 0,
    downloadedAt: (new Date()).getTime()
  },
  action,
) => {
  switch (action.type) {
    case AuthActionTypes.DOWNLOAD_ISSUE_REQUEST:
      return {
        ...state,
        issue: action.issue,
        jobId: action.jobId,
      };
    case AuthActionTypes.DOWNLOAD_ISSUE_IN_PROGRESS:
      return {
        ...state,
        progress: action.progress,
      };
    case AuthActionTypes.DOWNLOAD_ISSUE_COMPLETED:
      return {
        ...state,
        progress: 1,
        completed: true,
      };
    case AuthActionTypes.DOWNLOAD_ISSUE_FAILURE:
      return {
        ...state,
        issue: action.issue,
        error: action.error,
      };
    case AuthActionTypes.ISSUE_PAGE_INDEX:
      return {
        ...state,
        issue: {
          ...state.issue,
          pageIndex: action.pageIndex,
        },
        pageIndex: action.pageIndex,
      };
    default:
      return state;
  }
};

const library = (state = {}, action) => {
  if (!action.issue) {
    return state;
  }
  const {issue} = action;
  const key = issue.id;

  switch (action.type) {
    case AuthActionTypes.DOWNLOAD_ISSUE_REQUEST:
    case AuthActionTypes.DOWNLOAD_ISSUE_IN_PROGRESS:
    case AuthActionTypes.DOWNLOAD_ISSUE_FAILURE:
    case AuthActionTypes.DOWNLOAD_ISSUE_COMPLETED:
    case AuthActionTypes.ISSUE_PAGE_INDEX:
      return {
        ...state,
        [key]: updateIssue(state[key], action),
      };

    case AuthActionTypes.REMOVE_ISSUE:
      delete state[key];
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default library;
