import { map } from "lodash";
import { Issue } from "components";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import readerUrl from "config/readerUrl";
import { loadFavorites } from "store/actions/favorites";

function Favorites(props) {
  const dispatch = useDispatch();
  const favoriteIssuesPagination = useSelector(
    (state) => state?.pagination?.favoriteIssues["all"] || {}
  );
  const issues = useSelector((state) => state?.entities?.issues);
  const [favoriteIssues, setFavoriteIssues] = useState([]);
  const { isFetching = false, ids = [] } = favoriteIssuesPagination;

  useEffect(() => {
    //je filter uniquement les favorites
    setFavoriteIssues(
      ids.filter((id) => issues[id].favorite).map((id) => issues[id])
    );
  }, [ids, issues]);

  const loadData = useCallback(() => {
    dispatch(loadFavorites());
  }, [dispatch]);
  useEffect(() => {
    loadData();
  }, [loadData]);
  const onClick = (issue) => {
    window.location.href = readerUrl(issue);
  };
  return (
    <div className="min-vh-100">
      <div className="mt-3" />

      {isFetching && (
        <div className="container d-flex justify-content-center align-items-center">
          <i className="fas fa-spinner fa-spin fa-10x" />
        </div>
      )}

      <div className="container">
        <div className="category mt-4">
          <div className="row m-0">
            {map(favoriteIssues, (issue) => {
              return (
                <div key={issue?.id} className="col-6 col-sm-4 col-md-2 p-1">
                  <Issue
                    issueId={issue.id}
                    onDislike={(issue) => {
                      setFavoriteIssues(
                        favoriteIssues.filter((i) => i.id !== issue.id)
                      );
                    }}
                    onClick={(e) => {
                      onClick(issue);
                    }}
                  />
                </div>
              );
            })}
          </div>

          {!isFetching && favoriteIssuesPagination?.hasMoreResult && (
            <div className="d-flex justify-content-center mt-5">
              <button className="btn btn-primary" onClick={() => loadData()}>
                Charger encore plus ({favoriteIssuesPagination?.pageCount - 1}/
                {favoriteIssuesPagination?.lastPage}){" "}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Favorites;
