const initialState = [];

const favorites = function (state = initialState, action) {
  if (action.type === "TOGGLE_FAVORITE_PUBLICATION") {
    let nextState;

    const index = state.indexOf(action.id);

    if (index !== -1) {
      // Le titre est déjà dans les favoris, on le supprime de la liste
      nextState = state.filter((id) => action.id !== id);
    } else {
      // Le titre n'est pas dans les titres favoris, on l'ajoute à la liste
      nextState = [action.id, ...state];
    }
    return nextState || state;
  }
  return state;
};

export default favorites;
