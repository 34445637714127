import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Header() {
  const isAuth = useSelector((state) => Boolean(state?.profile?.user));
  return (
    <>
      <header>
        <nav className="navbar navbar-expand-md navbar-primary fixed-top bg-primary">
          <div className="container">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav me-auto mb-2 mb-md-0">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Kiosque
                  </Link>
                </li>
                {isAuth && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/favorites">
                      Ma Bibliothèque
                    </Link>
                  </li>
                )}
              </ul>
              <ul className="navbar-nav d-flex">
                {/* {props.user && !props.user.default_user && (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/account">
                      Mon compte
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-danger" to="/logout">
                      Se deconnecter
                    </Link>
                  </li>
                </>
              )} */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    entities: { categories },
    profile,
  } = state;

  return {
    categories,
    user: profile.user,
  };
};
export default connect(mapStateToProps)(Header);
