import { normalize, schema as Schema } from "normalizr";

import { camelizeKeys } from "humps";
import axios from "axios";

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (endpoint, schema, options = {}, useNormalizer = true) => {
  const config = {
    method: "get",
    url: endpoint,
    ...options,
  };

  return axios(config).then(({ data }) => {
    const camelizedJson = camelizeKeys(data);
    const { results } = camelizedJson;

    if (useNormalizer) {
      if (results) {
        return Object.assign(
          {},
          normalize(camelizedJson, { results: [schema] })
          // {
          //   ...rest,
          // },
        );
      }
      return Object.assign({}, normalize(camelizedJson, schema));
    }
    return Object.assign({}, camelizedJson);
    // const camelizedJson = camelizeKeys(data);
    // const { results, ...rest } = camelizedJson;

    // if (useNormalizer) {
    //   if (results) {
    //     return Object.assign(
    //       {},
    //       normalize(camelizedJson, { results: [schema] })
    //     );
    //     return Object.assign(
    //       {},
    //       normalize(camelizedJson, { results: [schema] }),
    //       {
    //         ...rest,
    //       }
    //     );
    //   }
    //   return Object.assign({}, normalize(camelizedJson, schema));
    // }
    // return Object.assign({}, camelizedJson);
  });
};

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/paularmstrong/normalizr

// GitHub's API may return results with uppercase letters while the query
// doesn't contain any. For example, "someuser" could result in "SomeUser"
// leading to a frozen UI as it wouldn't find "someuser" in the entities.
// That's why we're forcing lower cases down there.

const issueSchema = new Schema.Entity("issues", {});
const publicationSchema = new Schema.Entity("publications", {
  publicationNumber: issueSchema,
});
const categoryItemSchema = new Schema.Entity("categoryItems", {
  publication: publicationSchema,
  publicationNumber: issueSchema,
});

const categorySchema = new Schema.Entity("categories", {
  items: [categoryItemSchema],
});

const authenticationSchema = new Schema.Entity("authentication");

// Schemas for Github API responses.
export const Schemas = {
  ISSUE: issueSchema,
  ISSUE_ARRAY: [issueSchema],

  PUBLICATION: publicationSchema,
  PUBLICATION_ARRAY: [publicationSchema],

  KIOSK_CATEGORY_ITEM: categoryItemSchema,
  KIOSK_CATEGORY_ITEM_ARRAY: [categoryItemSchema],

  KIOSK_CATEGORY: categorySchema,
  KIOSK_CATEGORY_ARRAY: [categorySchema],
  AUTHENTICATION: authenticationSchema,
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = "Call API";

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
const api = (store) => (next) => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  let { endpoint } = callAPI;
  const { schema, types, options = {}, useNormalizer = true } = callAPI;

  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState());
  }
  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!schema) {
    throw new Error("Specify one of the exported Schemas.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }));

  return callApi(endpoint, schema, options, useNormalizer)
    .then((response) => {
      next(
        actionWith({
          response,
          type: successType,
        })
      );
    })
    .catch((err) => {
      const message = err?.response?.data
        ? err?.response?.data?.message
        : err?.message;
      !process.env.NODE_ENV ||
        (process.env.NODE_ENV === "development" &&
          console.log(failureType, message, endpoint));
      // console.log(err);
      // console.log(err?.response);
      next(
        actionWith({
          type: failureType,
          error: message,
          response: err?.response?.data,
        })
      );
    });
  // return callApi(endpoint, schema, options).then(
  //   (response) =>
  //     next(
  //       actionWith({
  //         response,
  //         type: successType,
  //       }),
  //     ),
  //   (error) =>
  //     next(
  //       actionWith({
  //         type: failureType,
  //         error: error.message || 'Something bad happened',
  //       }),
  //     ),
  // );
};

export default api;
