import { connect } from "react-redux";
import { authenticate } from "store/actions/authentication";

function Profile(props) {
  return <div className="container">{JSON.stringify(props.user)} </div>;
}

const mapStateToProps = (state) => {
  const { auth, profile } = state;

  return {
    auth,
    user: profile.user,
  };
};
export default connect(mapStateToProps, { authenticate })(Profile);
