import { CALL_API, Schemas } from "../middleware/api";

export const AUTHENTICATION_REQUEST = "AUTHENTICATION_REQUEST";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAILURE = "AUTHENTICATION_FAILURE";
export const LOGOUT = "LOGOUT";

const login = (data) => {
  return {
    [CALL_API]: {
      types: [
        AUTHENTICATION_REQUEST,
        AUTHENTICATION_SUCCESS,
        AUTHENTICATION_FAILURE,
      ],
      endpoint: "/authentication",
      schema: Schemas.AUTHENTICATION,
      useNormalizer: false,
      options: {
        method: "post",
        data: data,
      },
    },
  };
};

export const authenticate = (credentials) => (dispatch) => {
  return dispatch(
    login({
      // kiosk: 'fa2458e2c09dcf71d1be9afe464deb2d54bda06804898a7f8da1d165e10db7bd',
      // kiosk: 28,
      ...credentials,
    })
  );
};

export const PROFILE_REQUEST = "PROFILE_REQUEST";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILURE = "PROFILE_FAILURE";
export const PROFILE_SET = "PROFILE_SET";
export const SET_FREE_DAY = "SET_FREE_DAY";

const fetchMe = () => {
  return {
    [CALL_API]: {
      types: [PROFILE_REQUEST, PROFILE_SUCCESS, PROFILE_FAILURE],
      endpoint: "/reader",
      schema: {},
      useNormalizer: false,
      options: {
        // Authorization: "salon-grand-voyageur.28@propress.fr",
      },
    },
  };
};

export const profile = () => (dispatch) => {
  dispatch(fetchMe());
};

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

export const freeDays = (freeDays) => (dispatch) => {
  dispatch({
    type: SET_FREE_DAY,
    freeDays: freeDays,
  });
};

export const authenticateAndProfile = (credentials) => (dispatch) => {
  dispatch([authenticate(credentials), profile()]);
};
export const bmw = (licence) => (dispatch, store) => {
  dispatch([
    authenticate({
      licence,
      kiosk: 43,
    }),
  ]);
  // store.subscribe(() => {
  //   const { token, error } = store.getState().authentication;
  //   if (error) {
  //     //register
  //     // dispatch(register())
  //   }
  //   console.log({ token, error });
  // });
};
