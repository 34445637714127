import { get, join, pick } from "lodash";
import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { authenticate } from "store/actions/authentication";
import { updateUser } from "store/actions/user";

function PersonalInformation(props) {
  const baseUser = useSelector((state) => state?.profile?.user);
  const resUpdateUser = useSelector((state) => state?.updateUser);
  const { fetching = false } = resUpdateUser;
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    ...pick(
      { ...baseUser },
      ["email", "firstName", "lastName", "gender"],
      "mobilePhone"
    ),
    firstName: baseUser?.firstname || baseUser?.firstName,
    lastName: baseUser?.lastname || baseUser?.lastName,
  });

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const saveUser = (e) => {
    e?.preventDefault();
    dispatch(updateUser(user));
  };

  const getFieldError = (field) =>
    join(
      get(resUpdateUser?.response?.errors?.form?.children, field)?.errors,
      "/n"
    );
  const emailError = getFieldError("email");
  const firstNameError = getFieldError("firstName");
  const lastNameError = getFieldError("lastName");
  const genderError = getFieldError("gender");
  const mobilePhoneError = getFieldError("mobilePhone");
  const passwordError = getFieldError("currentPassword");

  return (
    <div className="container  min-vh-100">
      <div className="mt-5">
        {resUpdateUser?.response?.message && (
          <div className="alert alert-success my-3">
            {resUpdateUser.response.message}
          </div>
        )}

        <form
          name="account_form"
          method="post"
          onSubmit={saveUser}
          encType="multipart/form-data"
        >
          <div id="account_form">
            <div className="form-group">
              <label className="" htmlFor="account_form_gender">
                Genre
              </label>
              <select
                id="account_form_gender"
                name="gender"
                disabled={fetching}
                data-use-select2="true"
                data-placeholder=""
                data-allow-clear="true"
                className={`form-control flat ${
                  genderError ? "is-invalid" : ""
                }`}
                value={user?.gender}
                onChange={onChange}
              >
                <option value=""></option>
                <option value="female">Femme</option>
                <option value="male">Homme</option>
              </select>
              {genderError && (
                <div className="invalid-feedback">{genderError}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="account_form_firstname" className="required">
                Prénom
              </label>
              <input
                type="text"
                id="account_form_firstname"
                name="firstName"
                required="required"
                disabled={fetching}
                className={`form-control flat ${
                  firstNameError ? "is-invalid" : ""
                }`}
                value={user?.firstName}
                onChange={onChange}
              />
              {firstNameError && (
                <div className="invalid-feedback">{firstNameError}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="account_form_lastname" className="required">
                Nom
              </label>
              <input
                type="text"
                id="account_form_lastname"
                name="lastName"
                required="required"
                disabled={fetching}
                className={`form-control flat ${
                  lastNameError ? "is-invalid" : ""
                }`}
                value={user?.lastName}
                onChange={onChange}
              />
              {lastNameError && (
                <div className="invalid-feedback">{lastNameError}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="account_form_phoneNumber">Télephone</label>
              <input
                type="text"
                id="account_form_phoneNumber"
                name="mobilePhone"
                disabled={fetching}
                className={`form-control flat ${
                  mobilePhoneError ? "is-invalid" : ""
                }`}
                value={user?.mobilePhone || ""}
                onChange={onChange}
              />
              {mobilePhoneError && (
                <div className="invalid-feedback">{mobilePhoneError}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="account_form_email" className="required">
                Email
              </label>
              <input
                type="email"
                id="account_form_email"
                name="email"
                disabled={fetching}
                required="required"
                className={`form-control flat ${
                  emailError ? "is-invalid" : ""
                }`}
                value={user?.email}
                onChange={onChange}
              />
              {emailError && (
                <div className="invalid-feedback">{emailError}</div>
              )}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="account_form_current_password" className="required">
              Mot de passe actuel
            </label>
            <input
              type="password"
              id="account_form_current_password"
              name="currentPassword"
              required="required"
              disabled={fetching}
              autoComplete="current-password"
              className={`form-control flat ${
                passwordError ? "is-invalid" : ""
              }`}
              onChange={onChange}
            />
            {passwordError && (
              <div className="invalid-feedback">{passwordError}</div>
            )}
          </div>
          <button
            type="submit"
            className="w-100 btn btn-lg btn-secondary flat my-2"
          >
            {props.auth.authenticatting && (
              <>
                <i className="fa fa-spinner fa-spin p-3  text-light" />
                &nbsp;&nbsp;
              </>
            )}
            Enregistrer
          </button>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { auth, profile } = state;

  return {
    auth,
    user: profile.user,
  };
};
export default connect(mapStateToProps, { authenticate })(PersonalInformation);
