import { createStore, compose, applyMiddleware } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";

import reduxThunk from "redux-thunk";
import storage from "redux-persist/es/storage";

import rootReducers from "./reducers"; // where reducers is a object of reducers
// import package from "../../package.json";
import api from "./middleware/api";
import profile from "./middleware/profile";

const config = {
  key: process.env.REACT_APP_NAME,
  storage: storage,
  // debug: true //to get useful logging
  // will not be persisted
  blacklist: [
    "authentication",
    "resetting",
    "auth",
    "entities",
    "errorMessage",
    "registration",
    "confirmRegistration",
    "pagination",
    "freeDays",
  ],
};

const reduxMulti = ({ dispatch }) => {
  return (next) => (action) =>
    Array.isArray(action) ? action.filter(Boolean).map(dispatch) : next(action);
};

let mainMiddleware = [reduxThunk, reduxMulti, api, profile];

const configureStore = () => {
  return new Promise((resolve, reject) => {
    const reducers = persistCombineReducers(config, rootReducers);
    const enhancers = [applyMiddleware(...mainMiddleware)];

    const persistConfig = {
      enhancers,
    };
    const onComplete = () => {
      resolve(store);
    };
    const store = createStore(reducers, undefined, compose(...enhancers));

    persistStore(store, persistConfig, onComplete);
  });
};

export default configureStore;
