import { CALL_API, Schemas } from "../middleware/api";

export const USER_FAVORITE_ISSUES_REQUEST = "USER_FAVORITE_ISSUES_REQUEST";
export const USER_FAVORITE_ISSUES_SUCCESS = "USER_FAVORITE_ISSUES_SUCCESS";
export const USER_FAVORITE_ISSUES_FAILURE = "USER_FAVORITE_ISSUES_FAILURE";

// Fetches a page of stargazers for a particular repo.
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchFavorites = (options = {}) => ({
  [CALL_API]: {
    types: [
      USER_FAVORITE_ISSUES_REQUEST,
      USER_FAVORITE_ISSUES_SUCCESS,
      USER_FAVORITE_ISSUES_FAILURE,
    ],
    endpoint: "/reader/favorite/issues",
    schema: Schemas.ISSUE,
    options,
  },
});

// Fetches a page of stargazers for a particular repo.
// Bails out if page is cached and user didn't specifically request next page.
// Relies on Redux Thunk middleware.
export const loadFavorites = () => (dispatch, getState) => {
  const { pageCount = 1, hasMoreResult = true } =
    getState().pagination.favoriteIssues["all"] || {};

  if (!hasMoreResult) {
    return null;
  }

  return dispatch(
    fetchFavorites({
      params: {
        page: pageCount,
      },
    })
  );
};

export const USER_FAVORITE_ADD_ISSUE_REQUEST =
  "USER_FAVORITE_ADD_ISSUE_REQUEST";
export const USER_FAVORITE_ADD_ISSUE_SUCCESS =
  "USER_FAVORITE_ADD_ISSUE_SUCCESS";
export const USER_FAVORITE_ADD_ISSUE_FAILURE =
  "USER_FAVORITE_ADD_ISSUE_FAILURE";

// Fetches a page of stargazers for a particular repo.
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchAddIssueToFavorites = (id) => ({
  id,
  [CALL_API]: {
    types: [
      USER_FAVORITE_ADD_ISSUE_REQUEST,
      USER_FAVORITE_ADD_ISSUE_SUCCESS,
      USER_FAVORITE_ADD_ISSUE_FAILURE,
    ],
    endpoint: `/reader/favorite/issues/${id}`,
    schema: {},
    options: {
      method: "PUT",
    },
    useNormalizer: false,
  },
});

export const addIssueToFavorites = (id) => (dispatch) => {
  return dispatch(fetchAddIssueToFavorites(id));
};
export const USER_FAVORITE_REMOVE_ISSUE_REQUEST =
  "USER_FAVORITE_REMOVE_ISSUE_REQUEST";
export const USER_FAVORITE_REMOVE_ISSUE_SUCCESS =
  "USER_FAVORITE_REMOVE_ISSUE_SUCCESS";
export const USER_FAVORITE_REMOVE_ISSUE_FAILURE =
  "USER_FAVORITE_REMOVE_ISSUE_FAILURE";

// Fetches a page of stargazers for a particular repo.
// Relies on the custom API middleware defined in ../middleware/api.js.
const fetchRemoveIssueFromFavorites = (id) => ({
  id,
  [CALL_API]: {
    types: [
      USER_FAVORITE_REMOVE_ISSUE_REQUEST,
      USER_FAVORITE_REMOVE_ISSUE_SUCCESS,
      USER_FAVORITE_REMOVE_ISSUE_FAILURE,
    ],
    endpoint: `/reader/favorite/issues/${id}`,
    schema: {},
    options: {
      method: "DELETE",
    },
    useNormalizer: false,
  },
});

export const deleteIssueFromFavorites = (id) => (dispatch) => {
  return dispatch(fetchRemoveIssueFromFavorites(id));
};
