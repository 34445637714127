import { CALL_API } from "../middleware/api";
import { PROFILE_SET } from "./authentication";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

const doUpdateCurrentUser = (data) => {
  return {
    [CALL_API]: {
      types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
      endpoint: "/reader",
      schema: {},
      useNormalizer: false,
      options: {
        method: "patch",
        data: data,
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
      },
    },
  };
};

// Fetches a single user from Github API unless it is cached.
// Relies on Redux Thunk middleware.
export const updateUser = (data) => (dispatch) => {
  return dispatch(doUpdateCurrentUser(data));
};

// Fetches a single user from Github API unless it is cached.
// Relies on Redux Thunk middleware.
export const setUserInfo = (data) => (dispatch) => {
  return dispatch({
    type: PROFILE_SET,
    user: data,
  });
};
