import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { authenticate } from "store/actions/authentication";

function Licence() {
  const { licence } = useParams();

  const auth = useSelector((state) => state?.auth);
  const isAuth = useSelector((state) => Boolean(state?.profile?.user));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      authenticate({
        licence,
        kiosk: process.env.REACT_APP_KIOSK_ID,
      })
    );
  }, [dispatch, licence]);

  if (isAuth) {
    return <Redirect to="/" />;
  }
  return (
    <div className="min-vh-100">
      <div className="container">
        {auth?.authenticatting && (
          <div className="container d-flex justify-content-center min-vh-100 align-items-center">
            <i className="fas fa-spinner fa-spin fa-10x" />
          </div>
        )}
        {auth?.error && (
          <div className="mt-5">
            <div className="alert alert-danger text-center" role="alert">
              {auth?.error}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default Licence;
