import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";

import { loadIssue } from "store/actions/kiosk";
import imageNotFound from "images/image-not-found.png";
import { Redirect, useLocation } from "react-router-dom";
import {
  addIssueToFavorites,
  deleteIssueFromFavorites,
} from "store/actions/favorites";

const Issue = (props) => {
  const { issue, isAuth, onClick } = props;
  const [redirect] = useState();
  const _imgRef = useRef();

  useEffect(() => {
    if (props.issueId) {
      props.loadIssue(props.issueId, ["coverUrl"]);
    }
  }, [props, props.issueId]);

  const onError = (e) => {
    e.target.src = imageNotFound;
  };

  const location = useLocation();
  const dispatch = useDispatch();
  if (!issue) {
    return null;
  }
  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: { from: location },
        }}
      />
    );
  }

  return (
    <div className="issue">
      <img
        ref={_imgRef}
        alt={"Issue"}
        loading="lazy"
        data-not-found
        className="img img-fluid img-lg"
        src={issue?.coverUrl}
        onError={onError}
        onClick={onClick}
      />

      <div className="info">
        <div className={"d-flex d-row  align-items-center"}>
          <p className="title " onClick={onClick}>
            {issue?.publicationName}
          </p>
          {isAuth && (
            <>
              {/* <span className="fas fa-heart" /> */}
              <span
                className={`${
                  issue?.favorite ? "fas" : "far"
                } fa-heart curson-pointer`}
                onClick={(e) => {
                  e.preventDefault();

                  //remove from library
                  if (issue?.favorite) {
                    dispatch(deleteIssueFromFavorites(issue.id)).then(() => {
                      props.onDislike && props.onDislike(issue);
                    });
                  } else {
                    //add to library
                    dispatch(addIssueToFavorites(issue.id)).then(() => {
                      props.onLike && props.onLike(issue);
                    });
                  }
                }}
              />
            </>
          )}
        </div>
        <p className="published_at">
          {issue?.publishedAt
            ? moment(issue?.publishedAt).format("LL")
            : "\u00A0"}
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { issue: passIssueProp, issueId, isReadeable, disabled } = ownProps;
  const {
    entities: { issues },
    favoritePublications,
  } = state;
  const identifier = passIssueProp?.id || issueId;

  const issue = issues[identifier] || passIssueProp;
  const librearyItem = state.library[identifier];
  return {
    libraryItem: librearyItem,
    issue: issue,
    isReadeable:
      typeof isReadeable === "boolean"
        ? isReadeable
        : librearyItem && librearyItem.completed,
    issueId: identifier,
    isFavorite: favoritePublications.indexOf(issue?.publicationId) !== -1,
    disabled: typeof disabled === "boolean" ? disabled : !state.auth.token,
    isAuth: Boolean(state?.profile?.user),
  };
};
export default connect(mapStateToProps, { loadIssue })(Issue);
