import { CALL_API } from "../middleware/api";

export const RESETTING_REQUEST_REQUEST = "RESETTING_REQUEST_REQUEST";
export const RESETTING_REQUEST_SUCCESS = "RESETTING_REQUEST_SUCCESS";
export const RESETTING_REQUEST_FAILURE = "RESETTING_REQUEST_FAILURE";

const requestPassword = (data) => {
  return {
    [CALL_API]: {
      types: [
        RESETTING_REQUEST_REQUEST,
        RESETTING_REQUEST_SUCCESS,
        RESETTING_REQUEST_FAILURE,
      ],
      endpoint: "/resetting/request/password",
      schema: {},
      useNormalizer: false,
      options: {
        method: "post",
        data,
      },
    },
  };
};

export const resettingRequestPassword = (credentials) => (dispatch) => {
  return dispatch(
    requestPassword({
      ...credentials,
    })
  );
};

export const RESETTING_RESET_REQUEST = "RESETTING_RESET_REQUEST";
export const RESETTING_RESET_SUCCESS = "RESETTING_RESET_SUCCESS";
export const RESETTING_RESET_FAILURE = "RESETTING_RESET_FAILURE";

const resetPassword = (token, data) => {
  return {
    [CALL_API]: {
      types: [
        RESETTING_RESET_REQUEST,
        RESETTING_RESET_SUCCESS,
        RESETTING_RESET_FAILURE,
      ],
      endpoint: `/resetting/reset/password/${token}`,
      schema: {},
      useNormalizer: false,
      options: {
        method: "post",
        data,
      },
    },
  };
};

export const resettingResetPassword = (token, credentials) => (dispatch) => {
  return dispatch(
    resetPassword(token, {
      ...credentials,
    })
  );
};
