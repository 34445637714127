import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  HashRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Kiosk from "screens/Kiosk";
import Licence from "screens/Licence";
import Profile from "./Profile";
import Favorites from "./Favorites";

import { Header, Footer } from "components";

import Axios from "axios";
import PersonalInformation from "./account/PersonalInformation";

import logo from "images/logo.png";

const WrapperRouter = ({
  component: Component,
  showHeader,
  showFooter,
  ...rest
}) => (
  <Route
    render={(props) => (
      <React.Fragment>
        {showHeader && <Header />}
        {(showHeader || showFooter) && (
          <main className="flex-shrink-0">
            <div className="container">
              <div className="col-md-12 text-center">
                <p>Offre exclusive réservée aux licenciés</p>
                <Link to="/publications">
                  <img
                    className="center-block"
                    style={{ marginTop: "30px" }}
                    src={logo}
                    alt="logo"
                  />
                </Link>
              </div>
            </div>
            <Component {...props} />
          </main>
        )}
        {!showHeader && !showFooter && <Component {...props} />}
        {showFooter && <Footer />}
      </React.Fragment>
    )}
    {...rest}
  />
);

WrapperRouter.defaultProps = {
  showHeader: true,
  showFooter: true,
};

function PrivateRoute({ isAuth, ...rest }) {
  if (isAuth) {
    return <WrapperRouter {...rest} />;
  }

  return (
    <WrapperRouter
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )}
    />
  );
}

const EntryApp = (props) => {
  useEffect(() => {
    if (props.user) {
      Axios.defaults.headers.common["Authorization"] = props.user.apiKey;
    }
  }, [props.user]);
  return (
    <Router>
      <Switch>
        <WrapperRouter exact path="/" component={Kiosk} />
        <WrapperRouter path="/publications" component={Kiosk} />
        <WrapperRouter path="/licence/:licence" component={Licence} />
        <PrivateRoute
          path="/profile"
          isAuth={props.isAuth}
          component={Profile}
        />
        <PrivateRoute
          path="/account"
          isAuth={props.isAuth}
          component={PersonalInformation}
        />
        <PrivateRoute
          path="/account/personal-information"
          isAuth={props.isAuth}
          component={PersonalInformation}
        />
        <PrivateRoute
          path="/favorites"
          isAuth={props.isAuth}
          component={Favorites}
        />
        <WrapperRouter path="*" component={Kiosk} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  apiKey: state.profile?.user?.apiKey,
  kiosk: state.profile?.user?.kiosk,
  user: state.profile?.user,
  isAuth: Boolean(state.profile?.user),
});

export default connect(mapStateToProps)(EntryApp);
