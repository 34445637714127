import { union } from "lodash";

// Creates a reducer managing pagination, given the action types to handle,
// and a function telling how to extract the key from an action.
const paginate = ({ types, mapActionToKey }) => {
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected types to be an array of three elements.");
  }
  if (!types.every((t) => typeof t === "string")) {
    throw new Error("Expected types to be strings.");
  }
  if (mapActionToKey && typeof mapActionToKey !== "function") {
    throw new Error("Expected mapActionToKey to be a function.");
  }

  const [requestType, successType, failureType] = types;

  const updatePagination = (
    state = {
      isFetching: false,
      nextPageUrl: undefined,
      pageCount: 1,
      lastPage: 99,
      error: null,
      ids: [],
      hasMoreResult: true,
      isLoadingMore: false,
    },
    action
  ) => {
    switch (action.type) {
      case requestType:
        return {
          ...state,
          isFetching: true,
          isLoadingMore: action.isLoadingMore,
          error: null,
        };
      case successType:
        // console.warn(keys(action.response.result));
        //console.warn(action.response.result.results);
        const { results, currentPage, lastPage } = action.response.result;
        const hasMoreResult = currentPage < lastPage;
        return {
          ...state,
          isFetching: false,
          isLoadingMore: false,
          ids: union(state.ids, results),
          nextPageUrl: action.response.nextPageUrl,
          // pageCount: state.pageCount + 1,
          pageCount: hasMoreResult ? currentPage + 1 : currentPage,
          error: null,
          hasMoreResult,
          lastPage,
          // isLoadingMore: ,
        };
      case failureType:
        return {
          ...state,
          isFetching: false,
          isLoadingMore: false,
          error: action.error,
        };
      default:
        return state;
    }
  };

  return (state = {}, action) => {
    // Update pagination by key
    switch (action.type) {
      case requestType:
      case successType:
      case failureType:
        const key = mapActionToKey(action);
        if (typeof key !== "string") {
          throw new Error("Expected key to be a string.");
        }
        return {
          ...state,
          [key]: updatePagination(state[key], action),
        };
      default:
        return state;
    }
  };
};

export default paginate;
