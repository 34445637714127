import { CALL_API } from "../middleware/api";

export const REGISTRATION_REGISTER_REQUEST = "REGISTRATION_REGISTER_REQUEST";
export const REGISTRATION_REGISTER_SUCCESS = "REGISTRATION_REGISTER_SUCCESS";
export const REGISTRATION_REGISTER_FAILURE = "REGISTRATION_REGISTER_FAILURE";

const registration = (data) => {
  return {
    [CALL_API]: {
      types: [
        REGISTRATION_REGISTER_REQUEST,
        REGISTRATION_REGISTER_SUCCESS,
        REGISTRATION_REGISTER_FAILURE,
      ],
      endpoint: "/registration",
      schema: {},
      useNormalizer: false,
      options: {
        method: "post",
        data: data,
      },
    },
  };
};

export const register = (data) => (dispatch, getState) => {
  const freeDays = getState()?.freeDays || 30;
  return dispatch(
    registration({
      ...data,
      options: [
        JSON.stringify({
          freeDays,
        }),
      ],
    })
  );
};

export const CONFIRM_REGISTRATION_REQUEST = "CONFIRM_REGISTRATION_REQUEST";
export const CONFIRM_REGISTRATION_SUCCESS = "CONFIRM_REGISTRATION_SUCCESS";
export const CONFIRM_REGISTRATION_FAILURE = "CONFIRM_REGISTRATION_FAILURE";

const registrationConfirmation = (token) => {
  return {
    [CALL_API]: {
      types: [
        CONFIRM_REGISTRATION_REQUEST,
        CONFIRM_REGISTRATION_SUCCESS,
        CONFIRM_REGISTRATION_FAILURE,
      ],
      endpoint: `/registration/confirm/${token}`,
      schema: {},
      useNormalizer: false,
      options: {
        method: "post",
        data: { token, kiosk: process.version.REACT_APP_KIOSK_ID },
      },
    },
  };
};

export const confirmRegistration = (token) => (dispatch) => {
  return dispatch(registrationConfirmation(token));
};
