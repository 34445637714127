import logo from "images/logo.png";

const Splash = () => (
  <div className="h-100 d-flex flex-column px-4 justify-content-center align-items-center bg-light">
    <img src={logo} className="app-logo mb-3" alt="logo" />
    <h1 className="text-center ">
      Bienvenue sur le kiosque numérique {process.env.REACT_APP_NAME}
    </h1>
    <p className="small">
      <em>
        <u>By Groupe Conseil</u>
      </em>
    </p>
  </div>
);
export default Splash;
