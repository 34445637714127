import moment from "moment";
// import LKN from "images/lkn.svg";
import GroupeProPress from "images/groupepropress-v.svg";
function Footer() {
  return (
    <footer className="footer mt-5 py-3 bg-light">
      {/* <hr /> */}
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {/* <img src={LKN} alt="Le Kiosque Numérique" /> */}
            <img
              src={GroupeProPress}
              className="img img-fluid"
              alt="Le Kiosque Numérique"
            />
            <a href="http://cgu.kioskpress.fr/" className="text-muted">
              Conditions Générales d’Utilisation
            </a>
          </div>
          <div className="text-truncate">
            © {moment().format("Y")} - Tous droits réservés Groupe ProPress
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
