const apiFetch = ({ types }) => {
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected types to be an array of three elements.");
  }
  if (!types.every((t) => typeof t === "string")) {
    throw new Error("Expected types to be strings.");
  }

  const [requestType, successType, failureType] = types;

  const updateApi = (
    state = {
      fetching: false,
      error: null,
      response: null,
    },
    action
  ) => {
    switch (action.type) {
      case requestType:
        return {
          ...state,
          fetching: true,
          error: null,
          response: null,
        };
      case successType:
        return {
          ...state,
          fetching: false,
          error: null,
          response: action.response,
        };
      case failureType:
        return {
          ...state,
          fetching: false,
          error: action.error,
          response: action.response,
        };
      default:
        return state;
    }
  };

  return (state = {}, action) => {
    // Update pagination by key
    switch (action.type) {
      case requestType:
      case successType:
      case failureType:
        return {
          ...state,
          ...updateApi(state, action),
        };
      default:
        return state;
    }
  };
};

export default apiFetch;
