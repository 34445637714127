import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";

import axios from "axios";

import configureStore from "store";
import configureApp from "configApp";
import Splash from "screens/Splash";
import { loadCategories } from "store/actions/kiosk";
import EntryApp from "screens/EntryApp";
import { NetworkStatus } from "components";
import { ToastContainer } from "react-toastify";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState(null);

  useEffect(() => {
    configureStore().then((store) => {
      configureApp().then(() => {
        //current user
        const token = store.getState()?.profile?.user?.apiKey;

        axios.defaults.headers.common.Authorization =
          process.env.REACT_APP_API_SECRETE;

        //is user logged configure authorization and go to home page
        if (token) {
          axios.defaults.headers.common.Authorization = token;
        }
        store.dispatch(loadCategories());
        setStore(store);
        setLoading(false);
      });
    });
    // if (caches) {
    //   caches.keys().then(async function (names) {
    //     await Promise.all(names.map((name) => caches.delete(name)));
    //   });
    // }
    // if (document && document.cookie) {
    //   document.cookie.split(";").forEach((c) => {
    //     document.cookie = c
    //       .replace(/^ +/, "")
    //       .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    //   });
    // }
  }, []);

  if (loading) {
    return <Splash />;
  }
  return (
    <Provider store={store}>
      <NetworkStatus />
      <EntryApp />
      <ToastContainer />
    </Provider>
  );
};

export default App;
