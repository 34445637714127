import { forEach, map, merge, orderBy, split } from "lodash";
import { Issue } from "components";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadCategories } from "store/actions/kiosk";
import readerUrl from "config/readerUrl";
import { toast } from "react-toastify";

function Kiosk(props) {
  const { isFetching, isAuthenticated, location, history } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const getParameters = () => {
      let params = {},
        requestParams = {};
      const { search, hash } = location;
      requestParams = merge(split(search, "#"), split(search, "?"));
      requestParams = merge(requestParams, split(hash, "?"));
      requestParams = merge(requestParams, split(hash, "?"));
      // requestParams = _.merge(requestParams, _.split(hash,'#'));

      forEach(requestParams, (item, i) => {
        let splited = split(item, "=");
        if (splited.length === 2) {
          params[splited[0]] = splited[1];
        }
      });

      return params;
    };
    const params = getParameters();
    const { licence } = params;

    if (licence) {
      history.replace({
        pathname: `/licence/${licence}`,
      });
    }
  }, [location, history]);

  useEffect(() => {
    dispatch(loadCategories());
  }, [dispatch]);
  const onClick = (issueItem) => {
    if (isAuthenticated) {
      window.location.href = readerUrl(issueItem);
    } else {
      toast.warn("Vous n'êtes pas connecté(e).", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          width: "100%",
        },
      });
    }
  };
  return (
    <div className="min-vh-100">
      <div className="mt-3" />
      <NoAuthenticated />
      {isFetching && (
        <div className="container d-flex justify-content-center min-vh-100 align-items-center">
          <i className="fas fa-spinner fa-spin fa-10x" />
        </div>
      )}

      <div className="container">
        {map(props.categories, (category) => {
          return (
            <div
              className="category mt-4"
              key={category.id}
              id={`category-${category.id}`}
            >
              <div className="mb-4">
                <h4 className="text-secondary">{category.name}</h4>
                <h6>{category.subtitle}</h6>
              </div>
              <div className="row m-0">
                {map(category.items, (itemId) => {
                  const categoryItem = props.categoryItems[itemId];

                  return (
                    <div key={itemId} className="col-6 col-sm-4 col-md-2 p-1">
                      <Issue
                        issueId={categoryItem?.publicationNumber || itemId}
                        onClick={(e) => {
                          const baseIssue =
                            props.issues[categoryItem?.publicationNumber];
                          const issue = {
                            ...baseIssue,
                          };

                          onClick(issue);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const NoAuthenticated = () => {
  const isAuth = useSelector((state) => Boolean(state?.profile?.user));

  if (isAuth) {
    return null;
  }

  return (
    <div className="container">
      <div className="alert alert-danger text-center  my-5" role="alert">
        <p className="text-weight">Vous n'êtes actuellement pas connecté(e).</p>
        <p>Vous ne pouvez donc pas lire la presse.</p>
        <p>Pour vous connecter au kiosque passez par votre espace FFGolf.</p>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const {
    entities: { categories, categoryItems, issues, publications },
    pagination: { kioskCategories },
  } = state;

  return {
    categories: orderBy(categories, "position"),
    categoryItems,
    issues,
    publications,
    isFetching:
      kioskCategories[process.env.REACT_APP_KIOSK_ID]?.isFetching || false,
    isAuthenticated: Boolean(state?.profile?.user),
  };
};
export default connect(mapStateToProps)(Kiosk);
