import authentication, {
  profile,
  registration,
  confirmRegistration,
} from "./authentication";
import favoritePublications from "./favorites";
import { entities, pagination } from "./appData";
import library from "./library";
import resetting from "./resetting";
import apiFetch from "./basicapi";
import freeDays from "./freeDays";
import {
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "store/actions/user";

const reducers = {
  auth: authentication,
  profile,
  entities,
  pagination,
  favoritePublications,
  library,
  resetting,
  registration,
  confirmRegistration,
  freeDays,
  updateUser: apiFetch({
    types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
  }),
};

export default reducers;
