import * as AuthActionTypes from "../actions/authentication";
import * as RegistrationActionTypes from "../actions/registration";
import apiFetch from "./basicapi";

const authentication = (
  state = {
    authenticatting: false,
    error: null,
    token: null,
  },
  action
) => {
  switch (action.type) {
    case AuthActionTypes.AUTHENTICATION_REQUEST:
      return {
        ...state,
        authenticatting: true,
        error: null,
      };
    case AuthActionTypes.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        authenticatting: false,
        token: action.response.token,
        error: null,
      };
    case AuthActionTypes.AUTHENTICATION_FAILURE:
      return {
        ...state,
        authenticatting: false,
        error: action.error,
        token: null,
      };
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};

export const profile = (
  state = {
    isFetching: false,
    error: null,
    user: null,
  },
  action
) => {
  switch (action.type) {
    case AuthActionTypes.PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case AuthActionTypes.PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.response,
        error: null,
      };
    case AuthActionTypes.PROFILE_SET:
      return {
        ...state,
        isFetching: false,
        error: null,
        user: {
          ...state.user,
          ...action.user,
        },
      };
    case AuthActionTypes.PROFILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};
export const registration = apiFetch({
  types: [
    RegistrationActionTypes.REGISTRATION_REGISTER_REQUEST,
    RegistrationActionTypes.REGISTRATION_REGISTER_SUCCESS,
    RegistrationActionTypes.REGISTRATION_REGISTER_FAILURE,
  ],
});
export const confirmRegistration = apiFetch({
  types: [
    RegistrationActionTypes.CONFIRM_REGISTRATION_REQUEST,
    RegistrationActionTypes.CONFIRM_REGISTRATION_SUCCESS,
    RegistrationActionTypes.CONFIRM_REGISTRATION_FAILURE,
  ],
});
export default authentication;
