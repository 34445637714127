import * as ActionTypes from "../actions/resetting";

import { combineReducers } from "redux";
import apiFetch from "./basicapi";

// Updates the pagination data for different actions.
const resetting = combineReducers({
  requestPassword: apiFetch({
    // mapActionToKey: (action) => "requestPassword",
    types: [
      ActionTypes.RESETTING_REQUEST_REQUEST,
      ActionTypes.RESETTING_REQUEST_SUCCESS,
      ActionTypes.RESETTING_REQUEST_FAILURE,
    ],
  }),
  resetPassword: apiFetch({
    // mapActionToKey: (action) => "resetPassword",
    types: [
      ActionTypes.RESETTING_RESET_REQUEST,
      ActionTypes.RESETTING_RESET_SUCCESS,
      ActionTypes.RESETTING_RESET_FAILURE,
    ],
  }),
});
export default resetting;
