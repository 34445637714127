import * as KioskActionTypes from "../actions/kiosk";
import * as FavoriteActionTypes from "../actions/favorites";

import merge from "lodash/merge";
import paginate from "./paginate";
import kioskCategory from "./kioskCategory";
import { toast } from "react-toastify";

import { combineReducers } from "redux";
const ADDED_MESSAGE = `Vous venez d'ajouter la parution dans votre bibliothèque`;
const REMOVED_MESSAGE = `La parution vient d'être retirer de votre bibliothèque`;

// Updates an entity cache in response to any action with response.entities.
export const entities = (
  state = {
    publications: {},
    issues: {},
    categories: {},
    categoryItems: {},
  },
  action
) => {
  if (action.response && action.response.entities) {
    state = merge({}, state, action.response.entities);
  }
  if (
    [
      FavoriteActionTypes.USER_FAVORITE_ADD_ISSUE_SUCCESS,
      FavoriteActionTypes.USER_FAVORITE_REMOVE_ISSUE_SUCCESS,
    ].indexOf(action?.type) !== -1
  ) {
    state = {
      ...state,
      issues: {
        ...state.issues,
        [action.id]: {
          ...state.issues[action.id],
          favorite: !state.issues[action.id]?.favorite,
        },
      },
    };

    if (FavoriteActionTypes.USER_FAVORITE_ADD_ISSUE_SUCCESS === action?.type) {
      showToast(action?.response?.message || ADDED_MESSAGE);
    } else {
      showToast(action?.response?.message || REMOVED_MESSAGE, "warning");
    }
  }

  return state;
};
function showToast(message, type = "success") {
  toast(message, {
    type,
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
}
// Updates the pagination data for different actions.
export const pagination = combineReducers({
  // recipesByCookTime: paginate({
  //   mapActionToKey: (action) => action.cookTime.toString(),
  //   types: [
  //     RecipectionTypes.RECIPES_BY_COOK_TIME_RECIPES_REQUEST,
  //     RecipectionTypes.RECIPES_BY_COOK_TIME_RECIPES_SUCCESS,
  //     RecipectionTypes.RECIPES_BY_COOK_TIME_RECIPES_FAILURE,
  //   ],
  // }),
  // recipesByCategory: paginate({
  //   mapActionToKey: (action) => action.categoryId.toString(),
  //   types: [
  //     RecipectionTypes.RECIPES_BY_CATEGORY_REQUEST,
  //     RecipectionTypes.RECIPES_BY_CATEGORY_SUCCESS,
  //     RecipectionTypes.RECIPES_BY_CATEGORY_FAILURE,
  //   ],
  // }),
  kioskCategories: kioskCategory({
    mapActionToKey: (action) => action.kioskId.toString(),
    types: [
      KioskActionTypes.KIOSK_CATEGORIES_REQUEST,
      KioskActionTypes.KIOSK_CATEGORIES_SUCCESS,
      KioskActionTypes.KIOSK_CATEGORIES_FAILURE,
    ],
  }),
  categoryItems: paginate({
    mapActionToKey: (action) => action.categoryId.toString(),
    types: [
      KioskActionTypes.KIOSK_CATEGORY_ITEMS_REQUEST,
      KioskActionTypes.KIOSK_CATEGORY_ITEMS_SUCCESS,
      KioskActionTypes.KIOSK_CATEGORY_ITEMS_FAILURE,
    ],
  }),
  publications: paginate({
    mapActionToKey: (action) => action.publicationId.toString(),
    types: [
      KioskActionTypes.PUBLICATION_REQUEST,
      KioskActionTypes.PUBLICATION_SUCCESS,
      KioskActionTypes.PUBLICATION_FAILURE,
    ],
  }),
  publicationIssues: paginate({
    mapActionToKey: (action) => action.publicationId.toString(),
    types: [
      KioskActionTypes.PUBLICATION_ISSUES_REQUEST,
      KioskActionTypes.PUBLICATION_ISSUES_SUCCESS,
      KioskActionTypes.PUBLICATION_ISSUES_FAILURE,
    ],
  }),
  favoriteIssues: paginate({
    mapActionToKey: () => "all",
    types: [
      FavoriteActionTypes.USER_FAVORITE_ISSUES_REQUEST,
      FavoriteActionTypes.USER_FAVORITE_ISSUES_SUCCESS,
      FavoriteActionTypes.USER_FAVORITE_ISSUES_FAILURE,
    ],
  }),
});
