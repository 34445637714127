import { SET_FREE_DAY } from "store/actions/authentication";

const initialState = 30;

const freeDays = function (state = initialState, action) {
  if (action.type === SET_FREE_DAY) {
    return action.freeDays;
  }
  return state;
};

export default freeDays;
