import moment from "moment";
import "moment/min/locales.min";

import Axios from "axios";

const configureApp = async () => {
  // fallback if no available language fits
  const fallback = { languageTag: "fr", isRTL: false };

  const { languageTag } = fallback;

  // update layout direction

  //set moment locale
  moment.locale(languageTag);
  //axios base URL
  Axios.defaults.baseURL =
    process.env.REACT_APP_API_URL || "https://api.lekiosknumerique.com";
  //axios base URL
  // Axios.defaults.baseURL =  "https://lknpropress.ahamadi.dev.lekiosknumerique.com";

  //locale and version
  Axios.defaults.headers.common.locale = languageTag;
  Axios.defaults.headers.common["X-Accept-Version"] = "3";

  delete Axios.defaults.headers.common.Authorization;
};

export { configureApp as default };
